import type { MenuItem, Settings } from "~/types/common";
import type {
  Article,
  LandingCnat,
  Lot,
  ParkingsData,
  Program,
  Slice,
  Geography,
  SearchResults,
  Landing,
  Journey,
  ContentsItem,
  GeographyAreaItem,
  Magazine,
  PressReleases,
  PressRelease,
  Faqs,
  HomeData,
  SitemapData,
  SponsorshipPrograms,
  AccessGeographies,
} from "~/types/drupal_jsonapi";

import { cache } from "@solidjs/router";
import { getDataAtPath } from "~/lib/fetcherInternal";
import { createProgramWrapper } from "~/utils/program_wrapper";

export const getSettings = cache(async (): Promise<Settings> => {
  return getDataAtPath("solid/settings");
}, "settings");

export const getHome = cache(async (): Promise<HomeData> => {
  return getDataAtPath("solid/home");
}, "home");

export const getSitemap = cache(async (): Promise<SitemapData> => {
  return getDataAtPath("solid/sitemap");
}, "sitemap");

export const getMenuMain = cache(async (): Promise<MenuItem[]> => {
  return getDataAtPath("solid/menu/main");
}, "menu:main");

export const getMenuFooter = cache(async (): Promise<MenuItem[]> => {
  return getDataAtPath("solid/menu/footer");
}, "menu:footer");

export const getProgram = cache(async (id: string): Promise<Program> => {
  return getDataAtPath(`solid/program/${id}`);
}, "program");

export const getWrapper = cache(async (id: number) => {
  return Promise.all([
    getProgram(id.toString()),
    getProgramSlices(id.toString()),
    getProgramLots(id.toString()),
  ]).then(([program, slices, lots]) => {
    return createProgramWrapper({ program, slices, lots });
  });
}, "wrapper");

export const getSearchResults = cache(
  async (hash: string): Promise<SearchResults> => {
    return getDataAtPath(`solid/search/${hash}`);
  },
  "search",
);

export const getMagazine = cache(async (): Promise<Magazine> => {
  return getDataAtPath(`solid/magazine`);
}, "magazine");

export const getPressReleases = cache(async (): Promise<PressReleases> => {
  return getDataAtPath(`solid/press-releases`);
}, "press-releases");

export const getFaqsTop = cache(async (): Promise<Faqs> => {
  return getDataAtPath(`solid/faqs`);
}, "faqs");

export const searchFaq = cache(async (search: string): Promise<Faqs> => {
  return getDataAtPath(`solid/faqs-search/?search=${search}`);
}, "faq-search");

export const getProgramViewCount = cache(
  async (id: number): Promise<number> => {
    const json = await getDataAtPath<{ count: number }>(
      `solid/program/${id}/view-count`,
      {
        method: "PUT",
        bypassCache: true,
      },
    );
    return json.count;
  },
  "program-count",
);

export const getProgramSlices = cache(async (id: string): Promise<Slice[]> => {
  return getDataAtPath(`solid/program/${id}/slices`);
}, "slices");

export const getProgramLots = cache(async (id: string): Promise<Lot[]> => {
  return getDataAtPath(`solid/program/${id}/lots`);
}, "lots");

export const getAutopromoMagazineTeasers = cache(
  async (): Promise<Article[]> => {
    return getDataAtPath("solid/autopromo/magazine-teasers");
  },
  "autopromo-magazine-teasers",
);

export const getAutopromoContents = (ids: string): Promise<ContentsItem[]> =>
  getDataAtPath("solid/autopromo/contents/" + ids + "/");

export const getAutopromoAreas = (): Promise<GeographyAreaItem[]> =>
  getDataAtPath("solid/autopromo/areas/");

export const getAutopromoCnatMenuDwell = cache(
  async (): Promise<LandingCnat[]> => {
    return getDataAtPath("solid/autopromo/cnat-menu-dwell");
  },
  "solid/autopromo/cnat-menu-dwell",
);

export const getAutopromoCnatMenuInvest = cache(
  async (): Promise<LandingCnat[]> => {
    return getDataAtPath("solid/autopromo/cnat-menu-invest");
  },
  "solid/autopromo/cnat-menu-invest",
);

export const getAutopromoCnatMenuMixed = cache(
  async (): Promise<LandingCnat[]> => {
    return getDataAtPath("solid/autopromo/cnat-menu-mixed");
  },
  "solid/autopromo/cnat-menu-mixed",
);

export const getParkings = cache(
  async (department?: string, city?: string): Promise<ParkingsData> => {
    if (department && city) {
      return getDataAtPath(`solid/parkings/${department}/${city}`);
    }

    if (department && !city) {
      return getDataAtPath(`solid/parkings/${department}/${city}`);
    }

    return getDataAtPath("solid/parkings");
  },
  "parkings",
);

export const getGeography = cache(async (id: string): Promise<Geography> => {
  return getDataAtPath(`solid/geography/${id}`);
}, "geography");

export const getLanding = cache(async (id: string): Promise<Landing> => {
  return getDataAtPath(`solid/landing/${id}`);
}, "landing");

export const getJourney = cache(async (id: string): Promise<Journey> => {
  return getDataAtPath(`solid/journey/${id}`);
}, "journey");

export const getArticle = cache(async (id: string): Promise<Article> => {
  return getDataAtPath(`solid/article/${id}`);
}, "article");

export const getPressRelease = cache(
  async (id: string): Promise<PressRelease> => {
    return getDataAtPath(`solid/press-release/${id}`);
  },
  "press-release",
);

export const getNode = cache(
  async (id: string): Promise<Landing | Journey | Article> => {
    return getDataAtPath(`solid/node/${id}`);
  },
  "node",
);

export const getSponsorshipPrograms =
  async (): Promise<SponsorshipPrograms> => {
    return getDataAtPath("solid/search/sponsorship-programs");
  };

export const getAccessGeogaphies = async (): Promise<AccessGeographies> => {
  return getDataAtPath("solid/access/geographies");
};
